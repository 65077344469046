import { defineStore } from 'pinia'

interface StoreState {
  selectCart: any
  issueCart: any
}

export const useCartStore = defineStore('cartStore', {
  state: (): StoreState => ({
    selectCart: {},
    issueCart: {}
  }),
  actions: {
    setSelectCart(data: any) {
      this.selectCart = data
    },
    setISsueCart(data: any) {
      this.issueCart = data
    }
  },
  getters: {
    hasItem: state => {
      return state.selectCart?.items?.length > 0 || state.issueCart?.items?.length > 0
    }
  }
})
